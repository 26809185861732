
// Generated by peggy v. 2.0.1 (ts-pegjs plugin v. 3.1.0 )
//
// https://peggyjs.org/   https://github.com/metadevpro/ts-pegjs

"use strict";

export interface FilePosition {
  offset: number;
  line: number;
  column: number;
}

export interface FileRange {
  start: FilePosition;
  end: FilePosition;
  source: string;
}

export interface LiteralExpectation {
  type: "literal";
  text: string;
  ignoreCase: boolean;
}

export interface ClassParts extends Array<string | ClassParts> {}

export interface ClassExpectation {
  type: "class";
  parts: ClassParts;
  inverted: boolean;
  ignoreCase: boolean;
}

export interface AnyExpectation {
  type: "any";
}

export interface EndExpectation {
  type: "end";
}

export interface OtherExpectation {
  type: "other";
  description: string;
}

export type Expectation = LiteralExpectation | ClassExpectation | AnyExpectation | EndExpectation | OtherExpectation;

function peg$padEnd(str: string, targetLength: number, padString: string) {
  padString = padString || ' ';
  if (str.length > targetLength) {
    return str;
  }
  targetLength -= str.length;
  padString += padString.repeat(targetLength);
  return str + padString.slice(0, targetLength);
}

export class PeggySyntaxError extends Error {
  public static buildMessage(expected: Expectation[], found: string | null) {
    function hex(ch: string): string {
      return ch.charCodeAt(0).toString(16).toUpperCase();
    }

    function literalEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/"/g,  "\\\"")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function classEscape(s: string): string {
      return s
        .replace(/\\/g, "\\\\")
        .replace(/\]/g, "\\]")
        .replace(/\^/g, "\\^")
        .replace(/-/g,  "\\-")
        .replace(/\0/g, "\\0")
        .replace(/\t/g, "\\t")
        .replace(/\n/g, "\\n")
        .replace(/\r/g, "\\r")
        .replace(/[\x00-\x0F]/g,            (ch) => "\\x0" + hex(ch) )
        .replace(/[\x10-\x1F\x7F-\x9F]/g, (ch) => "\\x"  + hex(ch) );
    }

    function describeExpectation(expectation: Expectation) {
      switch (expectation.type) {
        case "literal":
          return "\"" + literalEscape(expectation.text) + "\"";
        case "class":
          const escapedParts = expectation.parts.map((part) => {
            return Array.isArray(part)
              ? classEscape(part[0] as string) + "-" + classEscape(part[1] as string)
              : classEscape(part);
          });

          return "[" + (expectation.inverted ? "^" : "") + escapedParts + "]";
        case "any":
          return "any character";
        case "end":
          return "end of input";
        case "other":
          return expectation.description;
      }
    }

    function describeExpected(expected1: Expectation[]) {
      const descriptions = expected1.map(describeExpectation);
      let i: number;
      let j: number;

      descriptions.sort();

      if (descriptions.length > 0) {
        for (i = 1, j = 1; i < descriptions.length; i++) {
          if (descriptions[i - 1] !== descriptions[i]) {
            descriptions[j] = descriptions[i];
            j++;
          }
        }
        descriptions.length = j;
      }

      switch (descriptions.length) {
        case 1:
          return descriptions[0];

        case 2:
          return descriptions[0] + " or " + descriptions[1];

        default:
          return descriptions.slice(0, -1).join(", ")
            + ", or "
            + descriptions[descriptions.length - 1];
      }
    }

    function describeFound(found1: string | null) {
      return found1 ? "\"" + literalEscape(found1) + "\"" : "end of input";
    }

    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
  }

  public message: string;
  public expected: Expectation[];
  public found: string | null;
  public location: FileRange;
  public name: string;

  constructor(message: string, expected: Expectation[], found: string | null, location: FileRange) {
    super();
    this.message = message;
    this.expected = expected;
    this.found = found;
    this.location = location;
    this.name = "PeggySyntaxError";

    if (typeof (Object as any).setPrototypeOf === "function") {
      (Object as any).setPrototypeOf(this, PeggySyntaxError.prototype);
    } else {
      (this as any).__proto__ = PeggySyntaxError.prototype;
    }
    if (typeof (Error as any).captureStackTrace === "function") {
      (Error as any).captureStackTrace(this, PeggySyntaxError);
    }
  }

  format(sources: { grammarSource?: string; text: string }[]): string {
    let str = 'Error: ' + this.message;
    if (this.location) {
      let src: string[] | null = null;
      let k;
      for (k = 0; k < sources.length; k++) {
        if (sources[k].grammarSource === this.location.source) {
          src = sources[k].text.split(/\r\n|\n|\r/g);
          break;
        }
      }
      let s = this.location.start;
      let loc = this.location.source + ':' + s.line + ':' + s.column;
      if (src) {
        let e = this.location.end;
        let filler = peg$padEnd('', s.line.toString().length, ' ');
        let line = src[s.line - 1];
        let last = s.line === e.line ? e.column : line.length + 1;
        str += '\n --> ' + loc + '\n' + filler + ' |\n' + s.line + ' | ' + line + '\n' + filler + ' | ' +
          peg$padEnd('', s.column - 1, ' ') +
          peg$padEnd('', last - s.column, '^');
      } else {
        str += '\n at ' + loc;
      }
    }
    return str;
  }
}

function peg$parse(input: string, options?: ParseOptions) {
  options = options !== undefined ? options : {};

  const peg$FAILED: Readonly<any> = {};
  const peg$source = options.grammarSource;

  const peg$startRuleFunctions: {[id: string]: any} = { start: peg$parsestart };
  let peg$startRuleFunction: () => any = peg$parsestart;

  const peg$c0 = function(lhs: any, rhs: any): any {
          if (rhs && rhs.length) {
              var exp = lhs, i = 0;
              for (; i < rhs.length; i++) {
                  if (rhs[i].op !== undefined) {
                      exp = {type: "binaryOp", operator: rhs[i].op, lhs: exp, rhs: rhs[i].rhs};
                  }
              }
              return exp;
          } else {
              return lhs;
          }
      };
  const peg$c1 = function(op: any, rhs: any): any { return {op: op, rhs: rhs}; };
  const peg$c2 = function(v: any): any { return {order: "random", fraction: v}; };
  const peg$c3 = function(v: any): any { return {order: "random", count: v}; };
  const peg$c4 = function(t: any, v: any): any { return {order: t, fraction: v}; };
  const peg$c5 = function(t: any, v: any): any { return {order: t, count: v}; };
  const peg$c6 = "limit";
  const peg$c7 = peg$literalExpectation("limit", false);
  const peg$c8 = "random";
  const peg$c9 = peg$literalExpectation("random", false);
  const peg$c10 = "highest value";
  const peg$c11 = peg$literalExpectation("highest value", false);
  const peg$c12 = "lowest value";
  const peg$c13 = peg$literalExpectation("lowest value", false);
  const peg$c14 = "highest future value";
  const peg$c15 = peg$literalExpectation("highest future value", false);
  const peg$c16 = "lowest future value";
  const peg$c17 = peg$literalExpectation("lowest future value", false);
  const peg$c18 = "most engaged";
  const peg$c19 = peg$literalExpectation("most engaged", false);
  const peg$c20 = "least engaged";
  const peg$c21 = peg$literalExpectation("least engaged", false);
  const peg$c22 = "most recently purchased";
  const peg$c23 = peg$literalExpectation("most recently purchased", false);
  const peg$c24 = "most recently engaged";
  const peg$c25 = peg$literalExpectation("most recently engaged", false);
  const peg$c26 = "most frequently purchased";
  const peg$c27 = peg$literalExpectation("most frequently purchased", false);
  const peg$c28 = "most frequently engaged";
  const peg$c29 = peg$literalExpectation("most frequently engaged", false);
  const peg$c30 = peg$otherExpectation("Boolean operator");
  const peg$c31 = peg$otherExpectation("and");
  const peg$c32 = "and";
  const peg$c33 = peg$literalExpectation("and", false);
  const peg$c34 = "&&";
  const peg$c35 = peg$literalExpectation("&&", false);
  const peg$c36 = "intersect";
  const peg$c37 = peg$literalExpectation("intersect", false);
  const peg$c38 = "&";
  const peg$c39 = peg$literalExpectation("&", false);
  const peg$c40 = function(): any { return "and"; };
  const peg$c41 = peg$otherExpectation("or");
  const peg$c42 = "or";
  const peg$c43 = peg$literalExpectation("or", false);
  const peg$c44 = "||";
  const peg$c45 = peg$literalExpectation("||", false);
  const peg$c46 = "union";
  const peg$c47 = peg$literalExpectation("union", false);
  const peg$c48 = "|";
  const peg$c49 = peg$literalExpectation("|", false);
  const peg$c50 = function(): any { return "or"; };
  const peg$c51 = peg$otherExpectation("except");
  const peg$c52 = "except";
  const peg$c53 = peg$literalExpectation("except", false);
  const peg$c54 = "-";
  const peg$c55 = peg$literalExpectation("-", false);
  const peg$c56 = function(): any { return "except"; };
  const peg$c57 = function(op: any, rhs: any): any { return {type: "unaryOp", operator: "not", rhs: rhs}; };
  const peg$c58 = peg$otherExpectation("not");
  const peg$c59 = "not ";
  const peg$c60 = peg$literalExpectation("not ", false);
  const peg$c61 = "~";
  const peg$c62 = peg$literalExpectation("~", false);
  const peg$c63 = "!";
  const peg$c64 = peg$literalExpectation("!", false);
  const peg$c65 = function(atom: any, limit: any): any { atom.limit = limit; return atom; };
  const peg$c66 = "(";
  const peg$c67 = peg$literalExpectation("(", false);
  const peg$c68 = ")";
  const peg$c69 = peg$literalExpectation(")", false);
  const peg$c70 = function(expr: any): any { return expr; };
  const peg$c71 = function(field: any, op: any, value: any): any { return {type: "fieldPredicate", field: field, operator: op === "is" ? "isDateRange" : "isNotDateRange", value: value}; };
  const peg$c72 = function(field: any, op: any, value: any): any { return {type: "fieldPredicate", field: field, operator: op, value: value}; };
  const peg$c73 = function(op: any, ids: any, option: any): any { return {type: "predicate", resource: "lists", event: op, id: ids, range: option || "all time", fields: []}; };
  const peg$c74 = function(op: any, ids: any, f: any, date: any): any { return {type: "predicate", resource: "lists", event: op, id: ids, range: date || "all time", fields: f || []}; };
  const peg$c75 = function(op: any, ids: any, date: any): any { return {type: "predicate", resource: "audiences", event: op, id: ids, range: date || "all time"}; };
  const peg$c76 = "clicked activities links";
  const peg$c77 = peg$literalExpectation("clicked activities links", false);
  const peg$c78 = "clicked activity links";
  const peg$c79 = peg$literalExpectation("clicked activity links", false);
  const peg$c80 = "clicked activity link";
  const peg$c81 = peg$literalExpectation("clicked activity link", false);
  const peg$c82 = function(ids: any, refs: any, date: any, aggregate: any): any { return {type: "predicate", resource: "links", event: "clicked", operands: [{id: ids}, {ref: refs}], range: date || "all time", aggregate: aggregate || null}; };
  const peg$c83 = "clicked links";
  const peg$c84 = peg$literalExpectation("clicked links", false);
  const peg$c85 = "clicked link";
  const peg$c86 = peg$literalExpectation("clicked link", false);
  const peg$c87 = function(refs: any, ids: any, date: any, aggregate: any): any { return {type: "predicate", resource: "links", event: "clicked", operands: [{id: ids || null}, {ref: refs}], range: date || "all time", aggregate: aggregate || null}; };
  const peg$c88 = "clicked link starts with";
  const peg$c89 = peg$literalExpectation("clicked link starts with", false);
  const peg$c90 = "match views";
  const peg$c91 = peg$literalExpectation("match views", false);
  const peg$c92 = "match view";
  const peg$c93 = peg$literalExpectation("match view", false);
  const peg$c94 = function(ids: any, withParam: any): any { return {type: "predicate", resource: "views", event: "match", id: ids, "with": withParam || null}; };
  const peg$c95 = "subscribed lists";
  const peg$c96 = peg$literalExpectation("subscribed lists", false);
  const peg$c97 = "subscribed list";
  const peg$c98 = peg$literalExpectation("subscribed list", false);
  const peg$c99 = function(): any { return "subscribed"; };
  const peg$c100 = "unsubscribed lists";
  const peg$c101 = peg$literalExpectation("unsubscribed lists", false);
  const peg$c102 = "unsubscribed list";
  const peg$c103 = peg$literalExpectation("unsubscribed list", false);
  const peg$c104 = function(): any { return "unsubscribed"; };
  const peg$c105 = "joined audiences";
  const peg$c106 = peg$literalExpectation("joined audiences", false);
  const peg$c107 = "joined audience";
  const peg$c108 = peg$literalExpectation("joined audience", false);
  const peg$c109 = function(): any { return "joined"; };
  const peg$c110 = "left audiences";
  const peg$c111 = peg$literalExpectation("left audiences", false);
  const peg$c112 = "left audience";
  const peg$c113 = peg$literalExpectation("left audience", false);
  const peg$c114 = function(): any { return "left"; };
  const peg$c115 = " ";
  const peg$c116 = peg$literalExpectation(" ", false);
  const peg$c117 = "any";
  const peg$c118 = peg$literalExpectation("any", false);
  const peg$c119 = function(event: any, date: any, aggregate: any): any { return {type: "predicate", resource: "any", event: event, range: date || "all time", id: "*", aggregate: aggregate || null}; };
  const peg$c120 = function(event: any, type: any, ids: any, date: any, aggregate: any): any { return {type: "predicate", resource: type, event: event, range: date || "all time", id: ids, aggregate: aggregate || null}; };
  const peg$c121 = function(event: any, activityType: any, date: any, aggregate: any): any { return {type: "predicate", resource: "activityType", event: event, range: date || "all time", ref: activityType, aggregate: aggregate || null}; };
  const peg$c122 = peg$otherExpectation("field comparison operator");
  const peg$c123 = "=";
  const peg$c124 = peg$literalExpectation("=", false);
  const peg$c125 = "!=";
  const peg$c126 = peg$literalExpectation("!=", false);
  const peg$c127 = ">=";
  const peg$c128 = peg$literalExpectation(">=", false);
  const peg$c129 = ">";
  const peg$c130 = peg$literalExpectation(">", false);
  const peg$c131 = "<=";
  const peg$c132 = peg$literalExpectation("<=", false);
  const peg$c133 = "<";
  const peg$c134 = peg$literalExpectation("<", false);
  const peg$c135 = "like";
  const peg$c136 = peg$literalExpectation("like", false);
  const peg$c137 = "not like";
  const peg$c138 = peg$literalExpectation("not like", false);
  const peg$c139 = "ilike";
  const peg$c140 = peg$literalExpectation("ilike", false);
  const peg$c141 = "not ilike";
  const peg$c142 = peg$literalExpectation("not ilike", false);
  const peg$c143 = "is not";
  const peg$c144 = peg$literalExpectation("is not", false);
  const peg$c145 = "is";
  const peg$c146 = peg$literalExpectation("is", false);
  const peg$c147 = "one of";
  const peg$c148 = peg$literalExpectation("one of", false);
  const peg$c149 = "not one of";
  const peg$c150 = peg$literalExpectation("not one of", false);
  const peg$c151 = "~*";
  const peg$c152 = peg$literalExpectation("~*", false);
  const peg$c153 = "!~*";
  const peg$c154 = peg$literalExpectation("!~*", false);
  const peg$c155 = "!~";
  const peg$c156 = peg$literalExpectation("!~", false);
  const peg$c157 = "@@";
  const peg$c158 = peg$literalExpectation("@@", false);
  const peg$c159 = "!@@";
  const peg$c160 = peg$literalExpectation("!@@", false);
  const peg$c161 = peg$otherExpectation("field date range operator");
  const peg$c162 = "activity";
  const peg$c163 = peg$literalExpectation("activity", false);
  const peg$c164 = "activities";
  const peg$c165 = peg$literalExpectation("activities", false);
  const peg$c166 = function(ids: any): any { return ids; };
  const peg$c167 = peg$otherExpectation("event name");
  const peg$c168 = "sent";
  const peg$c169 = peg$literalExpectation("sent", false);
  const peg$c170 = "clicked";
  const peg$c171 = peg$literalExpectation("clicked", false);
  const peg$c172 = "opened";
  const peg$c173 = peg$literalExpectation("opened", false);
  const peg$c174 = "subscribed";
  const peg$c175 = peg$literalExpectation("subscribed", false);
  const peg$c176 = "unsubscribed";
  const peg$c177 = peg$literalExpectation("unsubscribed", false);
  const peg$c178 = "updated";
  const peg$c179 = peg$literalExpectation("updated", false);
  const peg$c180 = "forwarded";
  const peg$c181 = peg$literalExpectation("forwarded", false);
  const peg$c182 = "replied";
  const peg$c183 = peg$literalExpectation("replied", false);
  const peg$c184 = "viewed";
  const peg$c185 = peg$literalExpectation("viewed", false);
  const peg$c186 = "entered";
  const peg$c187 = peg$literalExpectation("entered", false);
  const peg$c188 = "referred";
  const peg$c189 = peg$literalExpectation("referred", false);
  const peg$c190 = "converted";
  const peg$c191 = peg$literalExpectation("converted", false);
  const peg$c192 = "bounced";
  const peg$c193 = peg$literalExpectation("bounced", false);
  const peg$c194 = "liked";
  const peg$c195 = peg$literalExpectation("liked", false);
  const peg$c196 = "abandoned";
  const peg$c197 = peg$literalExpectation("abandoned", false);
  const peg$c198 = function(): any { return "activities"; };
  const peg$c199 = "campaigns";
  const peg$c200 = peg$literalExpectation("campaigns", false);
  const peg$c201 = "campaign";
  const peg$c202 = peg$literalExpectation("campaign", false);
  const peg$c203 = function(): any { return "campaigns"; };
  const peg$c204 = "revisions";
  const peg$c205 = peg$literalExpectation("revisions", false);
  const peg$c206 = "revision";
  const peg$c207 = peg$literalExpectation("revision", false);
  const peg$c208 = function(): any { return "revisions"; };
  const peg$c209 = "configurations";
  const peg$c210 = peg$literalExpectation("configurations", false);
  const peg$c211 = "configuration";
  const peg$c212 = peg$literalExpectation("configuration", false);
  const peg$c213 = function(): any { return "configurations"; };
  const peg$c214 = peg$otherExpectation("activity type");
  const peg$c215 = "email";
  const peg$c216 = peg$literalExpectation("email", false);
  const peg$c217 = "sms";
  const peg$c218 = peg$literalExpectation("sms", false);
  const peg$c219 = "whatsapp";
  const peg$c220 = peg$literalExpectation("whatsapp", false);
  const peg$c221 = "web";
  const peg$c222 = peg$literalExpectation("web", false);
  const peg$c223 = "displayad";
  const peg$c224 = peg$literalExpectation("displayad", false);
  const peg$c225 = "line";
  const peg$c226 = peg$literalExpectation("line", false);
  const peg$c227 = "pushnotification";
  const peg$c228 = peg$literalExpectation("pushnotification", false);
  const peg$c229 = peg$otherExpectation("count aggregate");
  const peg$c230 = "count";
  const peg$c231 = peg$literalExpectation("count", false);
  const peg$c232 = function(op: any, value: any): any { return ["count", op, value]; };
  const peg$c233 = function(d: any): any { return d; };
  const peg$c234 = "with";
  const peg$c235 = peg$literalExpectation("with", false);
  const peg$c236 = function(w: any): any { return w; };
  const peg$c237 = "birthday";
  const peg$c238 = peg$literalExpectation("birthday", false);
  const peg$c239 = function(date: any): any { return {type: "birthdayPredicate", range: date }; };
  const peg$c240 = function(field: any, otherFields: any): any { return [field].concat(otherFields || []); };
  const peg$c241 = function(field: any, op: any, value: any): any { return {field: field, operator: op === "is" ? "isDateRange" : "isNotDateRange", value: value}; };
  const peg$c242 = function(field: any, op: any, value: any): any { return {field: field, operator: op, value: value}; };
  const peg$c243 = ",";
  const peg$c244 = peg$literalExpectation(",", false);
  const peg$c245 = "purchased";
  const peg$c246 = peg$literalExpectation("purchased", false);
  const peg$c247 = function(f: any, date: any, aggregate: any): any {
          return {
              type: "purchasePredicate",
              fields: f || [],
              range: date || "all time",
              aggregate: aggregate || null
          };
      };
  const peg$c248 = "visited";
  const peg$c249 = peg$literalExpectation("visited", false);
  const peg$c250 = function(f: any, date: any, aggregate: any): any {
          return {
              type: "visitPredicate",
              event: "visit",
              fields: f || [],
              range: date || "all time",
              aggregate: aggregate || null
          };
      };
  const peg$c251 = "tracked";
  const peg$c252 = peg$literalExpectation("tracked", false);
  const peg$c253 = function(e: any, f: any, date: any, aggregate: any): any {
          return {
              type: "visitPredicate",
              event: e,
              fields: f || [],
              range: date || "all time",
              aggregate: aggregate || null
          };
      };
  const peg$c254 = peg$otherExpectation("event type");
  const peg$c255 = "open";
  const peg$c256 = peg$literalExpectation("open", false);
  const peg$c257 = "view";
  const peg$c258 = peg$literalExpectation("view", false);
  const peg$c259 = "abandon";
  const peg$c260 = peg$literalExpectation("abandon", false);
  const peg$c261 = "submit";
  const peg$c262 = peg$literalExpectation("submit", false);
  const peg$c263 = "update";
  const peg$c264 = peg$literalExpectation("update", false);
  const peg$c265 = "enter";
  const peg$c266 = peg$literalExpectation("enter", false);
  const peg$c267 = "partition";
  const peg$c268 = peg$literalExpectation("partition", false);
  const peg$c269 = function(f: any): any {
          return {
              type: "partitionPredicate",
              fields: f || [],
          };
      };
  const peg$c270 = "contactable";
  const peg$c271 = peg$literalExpectation("contactable", false);
  const peg$c272 = "via";
  const peg$c273 = peg$literalExpectation("via", false);
  const peg$c274 = function(channel: any): any { return {type: "contactablePredicate", channel: channel }; };
  const peg$c275 = "in audiences";
  const peg$c276 = peg$literalExpectation("in audiences", false);
  const peg$c277 = "in audience";
  const peg$c278 = peg$literalExpectation("in audience", false);
  const peg$c279 = function(ids: any): any { return {type: "predicate", resource: "audiences", event: "in", id: ids, range: "all time"}; };
  const peg$c280 = peg$otherExpectation("contact channel");
  const peg$c281 = /^[a-zA-Z_]/;
  const peg$c282 = peg$classExpectation([["a", "z"], ["A", "Z"], "_"], false, false);
  const peg$c283 = function(str: any): any { return str.join(""); };
  const peg$c284 = peg$otherExpectation("profile field");
  const peg$c285 = /^[a-zA-Z0-9_:]/;
  const peg$c286 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], "_", ":"], false, false);
  const peg$c287 = function(str: any): any { return str[0] + str[1].join(""); };
  const peg$c288 = "'";
  const peg$c289 = peg$literalExpectation("'", false);
  const peg$c290 = /^[^']/;
  const peg$c291 = peg$classExpectation(["'"], true, false);
  const peg$c292 = peg$otherExpectation("custom field");
  const peg$c293 = "cf:";
  const peg$c294 = peg$literalExpectation("cf:", false);
  const peg$c295 = function(field: any): any { return "cf:" + field; };
  const peg$c296 = peg$otherExpectation("subscriber field");
  const peg$c297 = "next";
  const peg$c298 = peg$literalExpectation("next", false);
  const peg$c299 = "last";
  const peg$c300 = peg$literalExpectation("last", false);
  const peg$c301 = "hour";
  const peg$c302 = peg$literalExpectation("hour", false);
  const peg$c303 = "day";
  const peg$c304 = peg$literalExpectation("day", false);
  const peg$c305 = "week";
  const peg$c306 = peg$literalExpectation("week", false);
  const peg$c307 = "month";
  const peg$c308 = peg$literalExpectation("month", false);
  const peg$c309 = "year";
  const peg$c310 = peg$literalExpectation("year", false);
  const peg$c311 = "hours";
  const peg$c312 = peg$literalExpectation("hours", false);
  const peg$c313 = "days";
  const peg$c314 = peg$literalExpectation("days", false);
  const peg$c315 = "weeks";
  const peg$c316 = peg$literalExpectation("weeks", false);
  const peg$c317 = "months";
  const peg$c318 = peg$literalExpectation("months", false);
  const peg$c319 = "years";
  const peg$c320 = peg$literalExpectation("years", false);
  const peg$c321 = peg$otherExpectation("date range");
  const peg$c322 = "yesterday";
  const peg$c323 = peg$literalExpectation("yesterday", false);
  const peg$c324 = "today";
  const peg$c325 = peg$literalExpectation("today", false);
  const peg$c326 = "tomorrow";
  const peg$c327 = peg$literalExpectation("tomorrow", false);
  const peg$c328 = "this week";
  const peg$c329 = peg$literalExpectation("this week", false);
  const peg$c330 = "this month";
  const peg$c331 = peg$literalExpectation("this month", false);
  const peg$c332 = "this year";
  const peg$c333 = peg$literalExpectation("this year", false);
  const peg$c334 = function(t: any): any { return t.join(""); };
  const peg$c335 = "ago";
  const peg$c336 = peg$literalExpectation("ago", false);
  const peg$c337 = "all time";
  const peg$c338 = peg$literalExpectation("all time", false);
  const peg$c339 = /^[0-9]/;
  const peg$c340 = peg$classExpectation([["0", "9"]], false, false);
  const peg$c341 = " to ";
  const peg$c342 = peg$literalExpectation(" to ", false);
  const peg$c343 = /^[01]/;
  const peg$c344 = peg$classExpectation(["0", "1"], false, false);
  const peg$c345 = /^[0-3]/;
  const peg$c346 = peg$classExpectation([["0", "3"]], false, false);
  const peg$c347 = function(from: any, to: any): any { return from.join("") + " to " + to.join(""); };
  const peg$c348 = "T";
  const peg$c349 = peg$literalExpectation("T", false);
  const peg$c350 = ":";
  const peg$c351 = peg$literalExpectation(":", false);
  const peg$c352 = peg$otherExpectation("value");
  const peg$c353 = peg$otherExpectation("numeric value");
  const peg$c354 = "null";
  const peg$c355 = peg$literalExpectation("null", false);
  const peg$c356 = function(id: any, otherIds: any): any { return [id].concat(otherIds || []); };
  const peg$c357 = /^[a-zA-Z]/;
  const peg$c358 = peg$classExpectation([["a", "z"], ["A", "Z"]], false, false);
  const peg$c359 = peg$otherExpectation("whitespace");
  const peg$c360 = /^[ \n\r]/;
  const peg$c361 = peg$classExpectation([" ", "\n", "\r"], false, false);
  const peg$c362 = peg$otherExpectation("percentage");
  const peg$c363 = "%";
  const peg$c364 = peg$literalExpectation("%", false);
  const peg$c365 = function(v: any): any { return v / 100.0; };
  const peg$c366 = peg$otherExpectation("integer");
  const peg$c367 = /^[0-9\-+]/;
  const peg$c368 = peg$classExpectation([["0", "9"], "-", "+"], false, false);
  const peg$c369 = function(digits: any): any { return parseInt(digits.join(""), 10); };
  const peg$c370 = peg$otherExpectation("real");
  const peg$c371 = ".";
  const peg$c372 = peg$literalExpectation(".", false);
  const peg$c373 = function(digits: any, frac: any): any { return parseFloat(digits.join("") + "." + frac.join("")); };
  const peg$c374 = peg$otherExpectation("string");
  const peg$c375 = "\"";
  const peg$c376 = peg$literalExpectation("\"", false);
  const peg$c377 = function(chars: any): any { return chars.join(""); };
  const peg$c378 = "\\";
  const peg$c379 = peg$literalExpectation("\\", false);
  const peg$c380 = peg$anyExpectation();
  const peg$c381 = function(char: any): any { return char; };
  const peg$c382 = function(seq: any): any { return seq; };
  const peg$c383 = peg$otherExpectation("escape sequence");
  const peg$c384 = "t";
  const peg$c385 = peg$literalExpectation("t", false);
  const peg$c386 = function(): any { return "\t"; };
  const peg$c387 = "n";
  const peg$c388 = peg$literalExpectation("n", false);
  const peg$c389 = function(): any { return "\n"; };
  const peg$c390 = "r";
  const peg$c391 = peg$literalExpectation("r", false);
  const peg$c392 = function(): any { return "\r"; };
  const peg$c393 = "u";
  const peg$c394 = peg$literalExpectation("u", false);
  const peg$c395 = /^[0-9a-fA-F]/;
  const peg$c396 = peg$classExpectation([["0", "9"], ["a", "f"], ["A", "F"]], false, false);
  const peg$c397 = function(digits: any): any {
          return String.fromCharCode(parseInt(digits.join(""), 16));
      };

  let peg$currPos = 0;
  let peg$savedPos = 0;
  const peg$posDetailsCache = [{ line: 1, column: 1 }];
  let peg$maxFailPos = 0;
  let peg$maxFailExpected: Expectation[] = [];
  let peg$silentFails = 0;

  let peg$result;

  if (options.startRule !== undefined) {
    if (!(options.startRule in peg$startRuleFunctions)) {
      throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
    }

    peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
  }

  function text(): string {
    return input.substring(peg$savedPos, peg$currPos);
  }

  function location(): FileRange {
    return peg$computeLocation(peg$savedPos, peg$currPos);
  }

  function expected(description: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildStructuredError(
      [peg$otherExpectation(description)],
      input.substring(peg$savedPos, peg$currPos),
      location1
    );
  }

  function error(message: string, location1?: FileRange) {
    location1 = location1 !== undefined
      ? location1
      : peg$computeLocation(peg$savedPos, peg$currPos);

    throw peg$buildSimpleError(message, location1);
  }

  function peg$literalExpectation(text1: string, ignoreCase: boolean): LiteralExpectation {
    return { type: "literal", text: text1, ignoreCase: ignoreCase };
  }

  function peg$classExpectation(parts: ClassParts, inverted: boolean, ignoreCase: boolean): ClassExpectation {
    return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
  }

  function peg$anyExpectation(): AnyExpectation {
    return { type: "any" };
  }

  function peg$endExpectation(): EndExpectation {
    return { type: "end" };
  }

  function peg$otherExpectation(description: string): OtherExpectation {
    return { type: "other", description: description };
  }

  function peg$computePosDetails(pos: number) {
    let details = peg$posDetailsCache[pos];
    let p;

    if (details) {
      return details;
    } else {
      p = pos - 1;
      while (!peg$posDetailsCache[p]) {
        p--;
      }

      details = peg$posDetailsCache[p];
      details = {
        line: details.line,
        column: details.column
      };

      while (p < pos) {
        if (input.charCodeAt(p) === 10) {
          details.line++;
          details.column = 1;
        } else {
          details.column++;
        }

        p++;
      }

      peg$posDetailsCache[pos] = details;

      return details;
    }
  }

  function peg$computeLocation(startPos: number, endPos: number): FileRange {
    const startPosDetails = peg$computePosDetails(startPos);
    const endPosDetails = peg$computePosDetails(endPos);

    return {
      source: peg$source,
      start: {
        offset: startPos,
        line: startPosDetails.line,
        column: startPosDetails.column
      },
      end: {
        offset: endPos,
        line: endPosDetails.line,
        column: endPosDetails.column
      }
    };
  }

  function peg$fail(expected1: Expectation) {
    if (peg$currPos < peg$maxFailPos) { return; }

    if (peg$currPos > peg$maxFailPos) {
      peg$maxFailPos = peg$currPos;
      peg$maxFailExpected = [];
    }

    peg$maxFailExpected.push(expected1);
  }

  function peg$buildSimpleError(message: string, location1: FileRange) {
    return new PeggySyntaxError(message, [], "", location1);
  }

  function peg$buildStructuredError(expected1: Expectation[], found: string | null, location1: FileRange) {
    return new PeggySyntaxError(
      PeggySyntaxError.buildMessage(expected1, found),
      expected1,
      found,
      location1
    );
  }

  function peg$parsestart(): any {
    let s0;

    s0 = peg$parseexpr();

    return s0;
  }

  function peg$parseexpr(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsefactor();
    if (s1 as any !== peg$FAILED) {
      s2 = [];
      s3 = peg$parseexprRhs();
      while (s3 as any !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parseexprRhs();
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c0(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsefactor();
    }

    return s0;
  }

  function peg$parseexprRhs(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parseboolOp();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsefactor();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c1(s2, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parselimit(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parserandTerm();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsepercentage();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c2(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parserandTerm();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parses();
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parseinteger();
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c3(s3);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsevalueTerm();
        if (s1 as any !== peg$FAILED) {
          s2 = peg$parses();
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parsepercentage();
            if (s3 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c4(s1, s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          s1 = peg$parsevalueTerm();
          if (s1 as any !== peg$FAILED) {
            s2 = peg$parses();
            if (s2 as any !== peg$FAILED) {
              s3 = peg$parseinteger();
              if (s3 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c5(s1, s3);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        }
      }
    }

    return s0;
  }

  function peg$parserandTerm(): any {
    let s0;

    if (input.substr(peg$currPos, 5) === peg$c6) {
      s0 = peg$c6;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c7); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 6) === peg$c8) {
        s0 = peg$c8;
        peg$currPos += 6;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c9); }
      }
    }

    return s0;
  }

  function peg$parsevalueTerm(): any {
    let s0;

    if (input.substr(peg$currPos, 13) === peg$c10) {
      s0 = peg$c10;
      peg$currPos += 13;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c11); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 12) === peg$c12) {
        s0 = peg$c12;
        peg$currPos += 12;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c13); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 20) === peg$c14) {
          s0 = peg$c14;
          peg$currPos += 20;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c15); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 19) === peg$c16) {
            s0 = peg$c16;
            peg$currPos += 19;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c17); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 12) === peg$c18) {
              s0 = peg$c18;
              peg$currPos += 12;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c19); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 13) === peg$c20) {
                s0 = peg$c20;
                peg$currPos += 13;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c21); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 23) === peg$c22) {
                  s0 = peg$c22;
                  peg$currPos += 23;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c23); }
                }
                if (s0 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 21) === peg$c24) {
                    s0 = peg$c24;
                    peg$currPos += 21;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c25); }
                  }
                  if (s0 as any === peg$FAILED) {
                    if (input.substr(peg$currPos, 25) === peg$c26) {
                      s0 = peg$c26;
                      peg$currPos += 25;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c27); }
                    }
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 23) === peg$c28) {
                        s0 = peg$c28;
                        peg$currPos += 23;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c29); }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parseboolOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parseandOp();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseorOp();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseexceptOp();
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c30); }
    }

    return s0;
  }

  function peg$parseandOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c32) {
      s1 = peg$c32;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c33); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c34) {
        s1 = peg$c34;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c35); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 9) === peg$c36) {
          s1 = peg$c36;
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c37); }
        }
        if (s1 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 38) {
            s1 = peg$c38;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c39); }
          }
        }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c40();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c31); }
    }

    return s0;
  }

  function peg$parseorOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 2) === peg$c42) {
      s1 = peg$c42;
      peg$currPos += 2;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c43); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c44) {
        s1 = peg$c44;
        peg$currPos += 2;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c45); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c46) {
          s1 = peg$c46;
          peg$currPos += 5;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c47); }
        }
        if (s1 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 124) {
            s1 = peg$c48;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c49); }
          }
        }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c50();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c41); }
    }

    return s0;
  }

  function peg$parseexceptOp(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 6) === peg$c52) {
      s1 = peg$c52;
      peg$currPos += 6;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c53); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 45) {
        s1 = peg$c54;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c55); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c56();
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c51); }
    }

    return s0;
  }

  function peg$parsefactor(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parsenotOp();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parselimitAtom();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c57(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parselimitAtom();
    }

    return s0;
  }

  function peg$parsenotOp(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c59) {
      s0 = peg$c59;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c60); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 126) {
        s0 = peg$c61;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c62); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 33) {
          s0 = peg$c63;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c64); }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c58); }
    }

    return s0;
  }

  function peg$parselimitAtom(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parseatom();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parselimit();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c65(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parseatom();
    }

    return s0;
  }

  function peg$parseatom(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 40) {
      s1 = peg$c66;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c67); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseexpr();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parses();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 41) {
              s5 = peg$c68;
              peg$currPos++;
            } else {
              s5 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c69); }
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c70(s3);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsehistoryPredicate();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parsebirthdayPredicate();
        if (s0 as any === peg$FAILED) {
          s0 = peg$parsepurchasePredicate();
          if (s0 as any === peg$FAILED) {
            s0 = peg$parsevisitPredicate();
            if (s0 as any === peg$FAILED) {
              s0 = peg$parsecontactablePredicate();
              if (s0 as any === peg$FAILED) {
                s0 = peg$parsetrackPredicate();
                if (s0 as any === peg$FAILED) {
                  s0 = peg$parsepartitionPredicate();
                  if (s0 as any === peg$FAILED) {
                    s0 = peg$parseaudienceMembershipPredicate();
                    if (s0 as any === peg$FAILED) {
                      s0 = peg$currPos;
                      s1 = peg$parsefield();
                      if (s1 as any !== peg$FAILED) {
                        s2 = peg$parses();
                        if (s2 as any === peg$FAILED) {
                          s2 = null;
                        }
                        if (s2 as any !== peg$FAILED) {
                          s3 = peg$parsefieldRangePredicateOp();
                          if (s3 as any !== peg$FAILED) {
                            s4 = peg$parsedateTerm();
                            if (s4 as any !== peg$FAILED) {
                              peg$savedPos = s0;
                              s1 = peg$c71(s1, s3, s4);
                              s0 = s1;
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                      if (s0 as any === peg$FAILED) {
                        s0 = peg$currPos;
                        s1 = peg$parsefield();
                        if (s1 as any !== peg$FAILED) {
                          s2 = peg$parses();
                          if (s2 as any === peg$FAILED) {
                            s2 = null;
                          }
                          if (s2 as any !== peg$FAILED) {
                            s3 = peg$parsefieldPredicateOp();
                            if (s3 as any !== peg$FAILED) {
                              s4 = peg$parses();
                              if (s4 as any === peg$FAILED) {
                                s4 = null;
                              }
                              if (s4 as any !== peg$FAILED) {
                                s5 = peg$parsevalue();
                                if (s5 as any !== peg$FAILED) {
                                  peg$savedPos = s0;
                                  s1 = peg$c72(s1, s3, s5);
                                  s0 = s1;
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                        if (s0 as any === peg$FAILED) {
                          s0 = peg$currPos;
                          s1 = peg$parsefield();
                          if (s1 as any !== peg$FAILED) {
                            s2 = peg$parses();
                            if (s2 as any === peg$FAILED) {
                              s2 = null;
                            }
                            if (s2 as any !== peg$FAILED) {
                              s3 = peg$parsefieldPredicateOp();
                              if (s3 as any !== peg$FAILED) {
                                s4 = peg$parses();
                                if (s4 as any === peg$FAILED) {
                                  s4 = null;
                                }
                                if (s4 as any !== peg$FAILED) {
                                  s5 = peg$parseexplicitCustomField();
                                  if (s5 as any !== peg$FAILED) {
                                    peg$savedPos = s0;
                                    s1 = peg$c72(s1, s3, s5);
                                    s0 = s1;
                                  } else {
                                    peg$currPos = s0;
                                    s0 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s0;
                                  s0 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s0;
                                s0 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s0;
                              s0 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s0;
                            s0 = peg$FAILED;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsehistoryPredicate(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parselistsPredicateOp();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseintegerList();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$currPos;
          peg$silentFails++;
          s5 = peg$parsecriteriaList();
          peg$silentFails--;
          if (s5 as any === peg$FAILED) {
            s4 = undefined;
          } else {
            peg$currPos = s4;
            s4 = peg$FAILED;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = peg$currPos;
            peg$silentFails++;
            s6 = peg$parsedateTerm();
            peg$silentFails--;
            if (s6 as any === peg$FAILED) {
              s5 = undefined;
            } else {
              peg$currPos = s5;
              s5 = peg$FAILED;
            }
            if (s5 as any !== peg$FAILED) {
              s6 = peg$parses();
              if (s6 as any !== peg$FAILED) {
                s7 = peg$parsestring();
                if (s7 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c73(s1, s3, s7);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parselistsPredicateOp();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parses();
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parseintegerList();
          if (s3 as any !== peg$FAILED) {
            s4 = peg$parsecriteriaList();
            if (s4 as any === peg$FAILED) {
              s4 = null;
            }
            if (s4 as any !== peg$FAILED) {
              s5 = peg$parsedateTerm();
              if (s5 as any === peg$FAILED) {
                s5 = null;
              }
              if (s5 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c74(s1, s3, s4, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseaudienceHistoryPredicateOp();
        if (s1 as any !== peg$FAILED) {
          s2 = peg$parses();
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parseintegerList();
            if (s3 as any !== peg$FAILED) {
              s4 = peg$parsedateTerm();
              if (s4 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c75(s1, s3, s4);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 24) === peg$c76) {
            s1 = peg$c76;
            peg$currPos += 24;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c77); }
          }
          if (s1 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 24) === peg$c76) {
              s1 = peg$c76;
              peg$currPos += 24;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c77); }
            }
            if (s1 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 22) === peg$c78) {
                s1 = peg$c78;
                peg$currPos += 22;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c79); }
              }
              if (s1 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 21) === peg$c80) {
                  s1 = peg$c80;
                  peg$currPos += 21;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c81); }
                }
              }
            }
          }
          if (s1 as any !== peg$FAILED) {
            s2 = peg$parses();
            if (s2 as any !== peg$FAILED) {
              s3 = peg$parseintegerList();
              if (s3 as any !== peg$FAILED) {
                s4 = peg$parses();
                if (s4 as any !== peg$FAILED) {
                  s5 = peg$parsestringList();
                  if (s5 as any !== peg$FAILED) {
                    s6 = peg$parsedateTerm();
                    if (s6 as any === peg$FAILED) {
                      s6 = null;
                    }
                    if (s6 as any !== peg$FAILED) {
                      s7 = peg$parsecountAggregate();
                      if (s7 as any === peg$FAILED) {
                        s7 = null;
                      }
                      if (s7 as any !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c82(s3, s5, s6, s7);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
          if (s0 as any === peg$FAILED) {
            s0 = peg$currPos;
            if (input.substr(peg$currPos, 13) === peg$c83) {
              s1 = peg$c83;
              peg$currPos += 13;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c84); }
            }
            if (s1 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 12) === peg$c85) {
                s1 = peg$c85;
                peg$currPos += 12;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c86); }
              }
            }
            if (s1 as any !== peg$FAILED) {
              s2 = peg$parses();
              if (s2 as any !== peg$FAILED) {
                s3 = peg$parsestringList();
                if (s3 as any !== peg$FAILED) {
                  s4 = peg$parseactivitiesTerm();
                  if (s4 as any === peg$FAILED) {
                    s4 = null;
                  }
                  if (s4 as any !== peg$FAILED) {
                    s5 = peg$parsedateTerm();
                    if (s5 as any === peg$FAILED) {
                      s5 = null;
                    }
                    if (s5 as any !== peg$FAILED) {
                      s6 = peg$parsecountAggregate();
                      if (s6 as any === peg$FAILED) {
                        s6 = null;
                      }
                      if (s6 as any !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c87(s3, s4, s5, s6);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
            if (s0 as any === peg$FAILED) {
              s0 = peg$currPos;
              if (input.substr(peg$currPos, 24) === peg$c88) {
                s1 = peg$c88;
                peg$currPos += 24;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c89); }
              }
              if (s1 as any !== peg$FAILED) {
                s2 = peg$parses();
                if (s2 as any !== peg$FAILED) {
                  s3 = peg$parsestring();
                  if (s3 as any !== peg$FAILED) {
                    s4 = peg$parseactivitiesTerm();
                    if (s4 as any === peg$FAILED) {
                      s4 = null;
                    }
                    if (s4 as any !== peg$FAILED) {
                      s5 = peg$parsedateTerm();
                      if (s5 as any === peg$FAILED) {
                        s5 = null;
                      }
                      if (s5 as any !== peg$FAILED) {
                        s6 = peg$parsecountAggregate();
                        if (s6 as any === peg$FAILED) {
                          s6 = null;
                        }
                        if (s6 as any !== peg$FAILED) {
                          peg$savedPos = s0;
                          s1 = peg$c87(s3, s4, s5, s6);
                          s0 = s1;
                        } else {
                          peg$currPos = s0;
                          s0 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
              if (s0 as any === peg$FAILED) {
                s0 = peg$currPos;
                if (input.substr(peg$currPos, 11) === peg$c90) {
                  s1 = peg$c90;
                  peg$currPos += 11;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c91); }
                }
                if (s1 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 10) === peg$c92) {
                    s1 = peg$c92;
                    peg$currPos += 10;
                  } else {
                    s1 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c93); }
                  }
                }
                if (s1 as any !== peg$FAILED) {
                  s2 = peg$parses();
                  if (s2 as any !== peg$FAILED) {
                    s3 = peg$parseintegerList();
                    if (s3 as any !== peg$FAILED) {
                      s4 = peg$parsewithTerm();
                      if (s4 as any === peg$FAILED) {
                        s4 = null;
                      }
                      if (s4 as any !== peg$FAILED) {
                        peg$savedPos = s0;
                        s1 = peg$c94(s3, s4);
                        s0 = s1;
                      } else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s0;
                      s0 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
                if (s0 as any === peg$FAILED) {
                  s0 = peg$parseresourcePredicateOp();
                }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parselistsPredicateOp(): any {
    let s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c95) {
      s1 = peg$c95;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c96); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 15) === peg$c97) {
        s1 = peg$c97;
        peg$currPos += 15;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c98); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c99();
    }
    s0 = s1;
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 18) === peg$c100) {
        s1 = peg$c100;
        peg$currPos += 18;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c101); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 17) === peg$c102) {
          s1 = peg$c102;
          peg$currPos += 17;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c103); }
        }
      }
      if (s1 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c104();
      }
      s0 = s1;
    }

    return s0;
  }

  function peg$parseaudienceHistoryPredicateOp(): any {
    let s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 16) === peg$c105) {
      s1 = peg$c105;
      peg$currPos += 16;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c106); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 15) === peg$c107) {
        s1 = peg$c107;
        peg$currPos += 15;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c108); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c109();
    }
    s0 = s1;
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 14) === peg$c110) {
        s1 = peg$c110;
        peg$currPos += 14;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c111); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 13) === peg$c112) {
          s1 = peg$c112;
          peg$currPos += 13;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c113); }
        }
      }
      if (s1 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c114();
      }
      s0 = s1;
    }

    return s0;
  }

  function peg$parseresourcePredicateOp(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7;

    s0 = peg$currPos;
    s1 = peg$parseeventName();
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 32) {
        s2 = peg$c115;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c116); }
      }
      if (s2 as any !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c117) {
          s3 = peg$c117;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c118); }
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsedateTerm();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsecountAggregate();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c119(s1, s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parseeventName();
      if (s1 as any !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 32) {
          s2 = peg$c115;
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c116); }
        }
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parseresourceType();
          if (s3 as any !== peg$FAILED) {
            s4 = peg$parses();
            if (s4 as any !== peg$FAILED) {
              s5 = peg$parseintegerList();
              if (s5 as any !== peg$FAILED) {
                s6 = peg$parsedateTerm();
                if (s6 as any === peg$FAILED) {
                  s6 = null;
                }
                if (s6 as any !== peg$FAILED) {
                  s7 = peg$parsecountAggregate();
                  if (s7 as any === peg$FAILED) {
                    s7 = null;
                  }
                  if (s7 as any !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c120(s1, s3, s5, s6, s7);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parseeventName();
        if (s1 as any !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 32) {
            s2 = peg$c115;
            peg$currPos++;
          } else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c116); }
          }
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parseactivityType();
            if (s3 as any !== peg$FAILED) {
              s4 = peg$parsedateTerm();
              if (s4 as any === peg$FAILED) {
                s4 = null;
              }
              if (s4 as any !== peg$FAILED) {
                s5 = peg$parsecountAggregate();
                if (s5 as any === peg$FAILED) {
                  s5 = null;
                }
                if (s5 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c121(s1, s3, s4, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parsefieldPredicateOp(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 61) {
      s0 = peg$c123;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c124); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c125) {
        s0 = peg$c125;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c126); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c127) {
          s0 = peg$c127;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c128); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 62) {
            s0 = peg$c129;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c130); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c131) {
              s0 = peg$c131;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 60) {
                s0 = peg$c133;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c134); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 4) === peg$c135) {
                  s0 = peg$c135;
                  peg$currPos += 4;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c136); }
                }
                if (s0 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 8) === peg$c137) {
                    s0 = peg$c137;
                    peg$currPos += 8;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c138); }
                  }
                  if (s0 as any === peg$FAILED) {
                    if (input.substr(peg$currPos, 5) === peg$c139) {
                      s0 = peg$c139;
                      peg$currPos += 5;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c140); }
                    }
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 9) === peg$c141) {
                        s0 = peg$c141;
                        peg$currPos += 9;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c142); }
                      }
                      if (s0 as any === peg$FAILED) {
                        if (input.substr(peg$currPos, 6) === peg$c143) {
                          s0 = peg$c143;
                          peg$currPos += 6;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c144); }
                        }
                        if (s0 as any === peg$FAILED) {
                          if (input.substr(peg$currPos, 2) === peg$c145) {
                            s0 = peg$c145;
                            peg$currPos += 2;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c146); }
                          }
                          if (s0 as any === peg$FAILED) {
                            if (input.substr(peg$currPos, 6) === peg$c147) {
                              s0 = peg$c147;
                              peg$currPos += 6;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c148); }
                            }
                            if (s0 as any === peg$FAILED) {
                              if (input.substr(peg$currPos, 10) === peg$c149) {
                                s0 = peg$c149;
                                peg$currPos += 10;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c150); }
                              }
                              if (s0 as any === peg$FAILED) {
                                if (input.substr(peg$currPos, 2) === peg$c151) {
                                  s0 = peg$c151;
                                  peg$currPos += 2;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c152); }
                                }
                                if (s0 as any === peg$FAILED) {
                                  if (input.charCodeAt(peg$currPos) === 126) {
                                    s0 = peg$c61;
                                    peg$currPos++;
                                  } else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c62); }
                                  }
                                  if (s0 as any === peg$FAILED) {
                                    if (input.substr(peg$currPos, 3) === peg$c153) {
                                      s0 = peg$c153;
                                      peg$currPos += 3;
                                    } else {
                                      s0 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c154); }
                                    }
                                    if (s0 as any === peg$FAILED) {
                                      if (input.substr(peg$currPos, 2) === peg$c155) {
                                        s0 = peg$c155;
                                        peg$currPos += 2;
                                      } else {
                                        s0 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c156); }
                                      }
                                      if (s0 as any === peg$FAILED) {
                                        if (input.substr(peg$currPos, 2) === peg$c157) {
                                          s0 = peg$c157;
                                          peg$currPos += 2;
                                        } else {
                                          s0 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c158); }
                                        }
                                        if (s0 as any === peg$FAILED) {
                                          if (input.substr(peg$currPos, 3) === peg$c159) {
                                            s0 = peg$c159;
                                            peg$currPos += 3;
                                          } else {
                                            s0 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c160); }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c122); }
    }

    return s0;
  }

  function peg$parsefieldRangePredicateOp(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 6) === peg$c143) {
      s0 = peg$c143;
      peg$currPos += 6;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c144); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c145) {
        s0 = peg$c145;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c146); }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c161); }
    }

    return s0;
  }

  function peg$parseactivitiesTerm(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 8) === peg$c162) {
        s2 = peg$c162;
        peg$currPos += 8;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c163); }
      }
      if (s2 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 10) === peg$c164) {
          s2 = peg$c164;
          peg$currPos += 10;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c165); }
        }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseintegerList();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c166(s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseeventName(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c168) {
      s0 = peg$c168;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c169); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 7) === peg$c170) {
        s0 = peg$c170;
        peg$currPos += 7;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c171); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 6) === peg$c172) {
          s0 = peg$c172;
          peg$currPos += 6;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c173); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 10) === peg$c174) {
            s0 = peg$c174;
            peg$currPos += 10;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c175); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 12) === peg$c176) {
              s0 = peg$c176;
              peg$currPos += 12;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c177); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 7) === peg$c178) {
                s0 = peg$c178;
                peg$currPos += 7;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c179); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 9) === peg$c180) {
                  s0 = peg$c180;
                  peg$currPos += 9;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c181); }
                }
                if (s0 as any === peg$FAILED) {
                  if (input.substr(peg$currPos, 7) === peg$c182) {
                    s0 = peg$c182;
                    peg$currPos += 7;
                  } else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c183); }
                  }
                  if (s0 as any === peg$FAILED) {
                    if (input.substr(peg$currPos, 6) === peg$c184) {
                      s0 = peg$c184;
                      peg$currPos += 6;
                    } else {
                      s0 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c185); }
                    }
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 7) === peg$c186) {
                        s0 = peg$c186;
                        peg$currPos += 7;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c187); }
                      }
                      if (s0 as any === peg$FAILED) {
                        if (input.substr(peg$currPos, 8) === peg$c188) {
                          s0 = peg$c188;
                          peg$currPos += 8;
                        } else {
                          s0 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c189); }
                        }
                        if (s0 as any === peg$FAILED) {
                          if (input.substr(peg$currPos, 9) === peg$c190) {
                            s0 = peg$c190;
                            peg$currPos += 9;
                          } else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c191); }
                          }
                          if (s0 as any === peg$FAILED) {
                            if (input.substr(peg$currPos, 7) === peg$c192) {
                              s0 = peg$c192;
                              peg$currPos += 7;
                            } else {
                              s0 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c193); }
                            }
                            if (s0 as any === peg$FAILED) {
                              if (input.substr(peg$currPos, 5) === peg$c194) {
                                s0 = peg$c194;
                                peg$currPos += 5;
                              } else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c195); }
                              }
                              if (s0 as any === peg$FAILED) {
                                if (input.substr(peg$currPos, 9) === peg$c196) {
                                  s0 = peg$c196;
                                  peg$currPos += 9;
                                } else {
                                  s0 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c197); }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c167); }
    }

    return s0;
  }

  function peg$parseresourceType(): any {
    let s0, s1;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 10) === peg$c164) {
      s1 = peg$c164;
      peg$currPos += 10;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c165); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 8) === peg$c162) {
        s1 = peg$c162;
        peg$currPos += 8;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c163); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c198();
    }
    s0 = s1;
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.substr(peg$currPos, 9) === peg$c199) {
        s1 = peg$c199;
        peg$currPos += 9;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c200); }
      }
      if (s1 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c201) {
          s1 = peg$c201;
          peg$currPos += 8;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c202); }
        }
      }
      if (s1 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c203();
      }
      s0 = s1;
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 9) === peg$c204) {
          s1 = peg$c204;
          peg$currPos += 9;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c205); }
        }
        if (s1 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 8) === peg$c206) {
            s1 = peg$c206;
            peg$currPos += 8;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c207); }
          }
        }
        if (s1 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c208();
        }
        s0 = s1;
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          if (input.substr(peg$currPos, 14) === peg$c209) {
            s1 = peg$c209;
            peg$currPos += 14;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c210); }
          }
          if (s1 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 13) === peg$c211) {
              s1 = peg$c211;
              peg$currPos += 13;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c212); }
            }
          }
          if (s1 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c213();
          }
          s0 = s1;
        }
      }
    }

    return s0;
  }

  function peg$parseactivityType(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 5) === peg$c215) {
      s0 = peg$c215;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c216); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c217) {
        s0 = peg$c217;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c218); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c219) {
          s0 = peg$c219;
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c220); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 3) === peg$c221) {
            s0 = peg$c221;
            peg$currPos += 3;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c222); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 9) === peg$c223) {
              s0 = peg$c223;
              peg$currPos += 9;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c224); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 4) === peg$c225) {
                s0 = peg$c225;
                peg$currPos += 4;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c226); }
              }
              if (s0 as any === peg$FAILED) {
                if (input.substr(peg$currPos, 16) === peg$c227) {
                  s0 = peg$c227;
                  peg$currPos += 16;
                } else {
                  s0 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c228); }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c214); }
    }

    return s0;
  }

  function peg$parsecountAggregate(): any {
    let s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c230) {
        s2 = peg$c230;
        peg$currPos += 5;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c231); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseaggregateOp();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseinteger();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c232(s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c229); }
    }

    return s0;
  }

  function peg$parseaggregateOp(): any {
    let s0;

    if (input.charCodeAt(peg$currPos) === 61) {
      s0 = peg$c123;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c124); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 2) === peg$c125) {
        s0 = peg$c125;
        peg$currPos += 2;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c126); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 2) === peg$c127) {
          s0 = peg$c127;
          peg$currPos += 2;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c128); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 62) {
            s0 = peg$c129;
            peg$currPos++;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c130); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 2) === peg$c131) {
              s0 = peg$c131;
              peg$currPos += 2;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c132); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.charCodeAt(peg$currPos) === 60) {
                s0 = peg$c133;
                peg$currPos++;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c134); }
              }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedateTermOrString(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsedateRangeOrString();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c233(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedateTerm(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsedateRange();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c233(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsewithTerm(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c234) {
        s2 = peg$c234;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c235); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsestring();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c236(s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsebirthdayPredicate(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 8) === peg$c237) {
      s1 = peg$c237;
      peg$currPos += 8;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c238); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsedateTerm();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c239(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecriteriaList(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriterionTerm();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsesubsequentCriterionTerm();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c240(s2, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecriterionTerm(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parsefield();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsefieldRangePredicateOp();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsedateTerm();
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c241(s1, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      s1 = peg$parsefield();
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parses();
        if (s2 as any === peg$FAILED) {
          s2 = null;
        }
        if (s2 as any !== peg$FAILED) {
          s3 = peg$parsefieldPredicateOp();
          if (s3 as any !== peg$FAILED) {
            s4 = peg$parses();
            if (s4 as any === peg$FAILED) {
              s4 = null;
            }
            if (s4 as any !== peg$FAILED) {
              s5 = peg$parsevalue();
              if (s5 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c242(s1, s3, s5);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
      if (s0 as any === peg$FAILED) {
        s0 = peg$currPos;
        s1 = peg$parsefield();
        if (s1 as any !== peg$FAILED) {
          s2 = peg$parses();
          if (s2 as any === peg$FAILED) {
            s2 = null;
          }
          if (s2 as any !== peg$FAILED) {
            s3 = peg$parsefieldPredicateOp();
            if (s3 as any !== peg$FAILED) {
              s4 = peg$parses();
              if (s4 as any === peg$FAILED) {
                s4 = null;
              }
              if (s4 as any !== peg$FAILED) {
                s5 = peg$parseexplicitCustomField();
                if (s5 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c242(s1, s3, s5);
                  s0 = s1;
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      }
    }

    return s0;
  }

  function peg$parsesubsequentCriterionTerm(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c243;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c244); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecriterionTerm();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsesubsequentCriterionTerm();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c240(s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsepurchasePredicate(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9) === peg$c245) {
      s1 = peg$c245;
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c246); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriteriaList();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsedateTerm();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecountAggregate();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c247(s2, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsevisitPredicate(): any {
    let s0, s1, s2, s3, s4;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c248) {
      s1 = peg$c248;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c249); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriteriaList();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsedateTerm();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecountAggregate();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c250(s2, s3, s4);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsetrackPredicate(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 7) === peg$c251) {
      s1 = peg$c251;
      peg$currPos += 7;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c252); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parsetrackEventType();
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsecriteriaList();
          if (s4 as any === peg$FAILED) {
            s4 = null;
          }
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsedateTerm();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              s6 = peg$parsecountAggregate();
              if (s6 as any === peg$FAILED) {
                s6 = null;
              }
              if (s6 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c253(s3, s4, s5, s6);
                s0 = s1;
              } else {
                peg$currPos = s0;
                s0 = peg$FAILED;
              }
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsetrackEventType(): any {
    let s0, s1;

    peg$silentFails++;
    if (input.substr(peg$currPos, 4) === peg$c255) {
      s0 = peg$c255;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c256); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c257) {
        s0 = peg$c257;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c258); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 7) === peg$c259) {
          s0 = peg$c259;
          peg$currPos += 7;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c260); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c261) {
            s0 = peg$c261;
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c262); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 6) === peg$c263) {
              s0 = peg$c263;
              peg$currPos += 6;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c264); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 5) === peg$c265) {
                s0 = peg$c265;
                peg$currPos += 5;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c266); }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c254); }
    }

    return s0;
  }

  function peg$parsepartitionPredicate(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 9) === peg$c267) {
      s1 = peg$c267;
      peg$currPos += 9;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c268); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsecriteriaList();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c269(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsecontactablePredicate(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 11) === peg$c270) {
      s1 = peg$c270;
      peg$currPos += 11;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c271); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        if (input.substr(peg$currPos, 3) === peg$c272) {
          s3 = peg$c272;
          peg$currPos += 3;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c273); }
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parses();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsechannel();
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c274(s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parseaudienceMembershipPredicate(): any {
    let s0, s1, s2, s3;

    s0 = peg$currPos;
    if (input.substr(peg$currPos, 12) === peg$c275) {
      s1 = peg$c275;
      peg$currPos += 12;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c276); }
    }
    if (s1 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 11) === peg$c277) {
        s1 = peg$c277;
        peg$currPos += 11;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c278); }
      }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parses();
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parseintegerList();
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c279(s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsechannel(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c281.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c282); }
    }
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        if (peg$c281.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c282); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c283(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c280); }
    }

    return s0;
  }

  function peg$parsefieldVal(): any {
    let s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$currPos;
    if (peg$c281.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c282); }
    }
    if (s2 as any !== peg$FAILED) {
      s3 = [];
      if (peg$c285.test(input.charAt(peg$currPos))) {
        s4 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s4 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c286); }
      }
      while (s4 as any !== peg$FAILED) {
        s3.push(s4);
        if (peg$c285.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c286); }
        }
      }
      if (s3 as any !== peg$FAILED) {
        s2 = [s2, s3];
        s1 = s2;
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c287(s1);
    }
    s0 = s1;
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 39) {
        s1 = peg$c288;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c289); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = [];
        if (peg$c290.test(input.charAt(peg$currPos))) {
          s3 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c291); }
        }
        if (s3 as any !== peg$FAILED) {
          while (s3 as any !== peg$FAILED) {
            s2.push(s3);
            if (peg$c290.test(input.charAt(peg$currPos))) {
              s3 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s3 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c291); }
            }
          }
        } else {
          s2 = peg$FAILED;
        }
        if (s2 as any !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 39) {
            s3 = peg$c288;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c289); }
          }
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c283(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c284); }
    }

    return s0;
  }

  function peg$parseexplicitCustomField(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.substr(peg$currPos, 3) === peg$c293) {
      s1 = peg$c293;
      peg$currPos += 3;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c294); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsefieldVal();
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c295(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c292); }
    }

    return s0;
  }

  function peg$parsefield(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parseexplicitCustomField();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsefieldVal();
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c296); }
    }

    return s0;
  }

  function peg$parsedateRangeOrString(): any {
    let s0;

    s0 = peg$parsedateRange();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsestring();
    }

    return s0;
  }

  function peg$parsedateDirection(): any {
    let s0;

    if (input.substr(peg$currPos, 4) === peg$c297) {
      s0 = peg$c297;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c298); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c299) {
        s0 = peg$c299;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c300); }
      }
    }

    return s0;
  }

  function peg$parsedateUnitSingular(): any {
    let s0;

    if (input.substr(peg$currPos, 4) === peg$c301) {
      s0 = peg$c301;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c302); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 3) === peg$c303) {
        s0 = peg$c303;
        peg$currPos += 3;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c304); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 4) === peg$c305) {
          s0 = peg$c305;
          peg$currPos += 4;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c306); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 5) === peg$c307) {
            s0 = peg$c307;
            peg$currPos += 5;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c308); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c309) {
              s0 = peg$c309;
              peg$currPos += 4;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c310); }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedateUnitPlural(): any {
    let s0;

    if (input.substr(peg$currPos, 5) === peg$c311) {
      s0 = peg$c311;
      peg$currPos += 5;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c312); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c313) {
        s0 = peg$c313;
        peg$currPos += 4;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c314); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 5) === peg$c315) {
          s0 = peg$c315;
          peg$currPos += 5;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c316); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 6) === peg$c317) {
            s0 = peg$c317;
            peg$currPos += 6;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c318); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c319) {
              s0 = peg$c319;
              peg$currPos += 5;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c320); }
            }
          }
        }
      }
    }

    return s0;
  }

  function peg$parsedateRange(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    if (input.substr(peg$currPos, 9) === peg$c322) {
      s0 = peg$c322;
      peg$currPos += 9;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c323); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.substr(peg$currPos, 5) === peg$c324) {
        s0 = peg$c324;
        peg$currPos += 5;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c325); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.substr(peg$currPos, 8) === peg$c326) {
          s0 = peg$c326;
          peg$currPos += 8;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c327); }
        }
        if (s0 as any === peg$FAILED) {
          if (input.substr(peg$currPos, 9) === peg$c328) {
            s0 = peg$c328;
            peg$currPos += 9;
          } else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c329); }
          }
          if (s0 as any === peg$FAILED) {
            if (input.substr(peg$currPos, 10) === peg$c330) {
              s0 = peg$c330;
              peg$currPos += 10;
            } else {
              s0 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c331); }
            }
            if (s0 as any === peg$FAILED) {
              if (input.substr(peg$currPos, 9) === peg$c332) {
                s0 = peg$c332;
                peg$currPos += 9;
              } else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c333); }
              }
              if (s0 as any === peg$FAILED) {
                s0 = peg$currPos;
                s1 = peg$currPos;
                s2 = peg$parsedateDirection();
                if (s2 as any !== peg$FAILED) {
                  s3 = peg$parses();
                  if (s3 as any !== peg$FAILED) {
                    s4 = peg$parsedateUnitSingular();
                    if (s4 as any !== peg$FAILED) {
                      s2 = [s2, s3, s4];
                      s1 = s2;
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
                if (s1 as any !== peg$FAILED) {
                  peg$savedPos = s0;
                  s1 = peg$c334(s1);
                }
                s0 = s1;
                if (s0 as any === peg$FAILED) {
                  s0 = peg$currPos;
                  s1 = peg$currPos;
                  s2 = peg$parsedateDirection();
                  if (s2 as any !== peg$FAILED) {
                    s3 = peg$parses();
                    if (s3 as any !== peg$FAILED) {
                      s4 = peg$parseinteger();
                      if (s4 as any !== peg$FAILED) {
                        s5 = peg$parses();
                        if (s5 as any !== peg$FAILED) {
                          s6 = peg$parsedateUnitPlural();
                          if (s6 as any !== peg$FAILED) {
                            s2 = [s2, s3, s4, s5, s6];
                            s1 = s2;
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                  if (s1 as any !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c334(s1);
                  }
                  s0 = s1;
                  if (s0 as any === peg$FAILED) {
                    s0 = peg$currPos;
                    s1 = peg$currPos;
                    s2 = peg$parseinteger();
                    if (s2 as any !== peg$FAILED) {
                      s3 = peg$parses();
                      if (s3 as any !== peg$FAILED) {
                        s4 = peg$parsedateUnitPlural();
                        if (s4 as any === peg$FAILED) {
                          s4 = peg$parsedateUnitSingular();
                        }
                        if (s4 as any !== peg$FAILED) {
                          s5 = peg$parses();
                          if (s5 as any !== peg$FAILED) {
                            if (input.substr(peg$currPos, 3) === peg$c335) {
                              s6 = peg$c335;
                              peg$currPos += 3;
                            } else {
                              s6 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c336); }
                            }
                            if (s6 as any !== peg$FAILED) {
                              s2 = [s2, s3, s4, s5, s6];
                              s1 = s2;
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                    if (s1 as any !== peg$FAILED) {
                      peg$savedPos = s0;
                      s1 = peg$c334(s1);
                    }
                    s0 = s1;
                    if (s0 as any === peg$FAILED) {
                      if (input.substr(peg$currPos, 8) === peg$c337) {
                        s0 = peg$c337;
                        peg$currPos += 8;
                      } else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c338); }
                      }
                      if (s0 as any === peg$FAILED) {
                        s0 = peg$parsedateFromTo();
                        if (s0 as any === peg$FAILED) {
                          s0 = peg$parsedateTimeFromTo();
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c321); }
    }

    return s0;
  }

  function peg$parsedateFromTo(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (peg$c339.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c340); }
    }
    if (s2 as any !== peg$FAILED) {
      if (peg$c339.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c340); }
      }
      if (s3 as any !== peg$FAILED) {
        if (peg$c339.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c340); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c339.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c340); }
          }
          if (s5 as any !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 45) {
              s6 = peg$c54;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c55); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c339.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c340); }
              }
              if (s7 as any !== peg$FAILED) {
                if (peg$c339.test(input.charAt(peg$currPos))) {
                  s8 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c340); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 45) {
                    s9 = peg$c54;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c55); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c339.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c340); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (peg$c339.test(input.charAt(peg$currPos))) {
                        s11 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c340); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        s2 = [s2, s3, s4, s5, s6, s7, s8, s9, s10, s11];
                        s1 = s2;
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c341) {
        s2 = peg$c341;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c342); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        if (peg$c339.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c340); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c339.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c340); }
          }
          if (s5 as any !== peg$FAILED) {
            if (peg$c339.test(input.charAt(peg$currPos))) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c340); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c339.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c340); }
              }
              if (s7 as any !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 45) {
                  s8 = peg$c54;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c55); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (peg$c343.test(input.charAt(peg$currPos))) {
                    s9 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c344); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c339.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c340); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 45) {
                        s11 = peg$c54;
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c55); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        if (peg$c345.test(input.charAt(peg$currPos))) {
                          s12 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c346); }
                        }
                        if (s12 as any !== peg$FAILED) {
                          if (peg$c339.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c340); }
                          }
                          if (s13 as any !== peg$FAILED) {
                            s4 = [s4, s5, s6, s7, s8, s9, s10, s11, s12, s13];
                            s3 = s4;
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c347(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsedateTimeFromTo(): any {
    let s0, s1, s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22;

    s0 = peg$currPos;
    s1 = peg$currPos;
    if (peg$c339.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c340); }
    }
    if (s2 as any !== peg$FAILED) {
      if (peg$c339.test(input.charAt(peg$currPos))) {
        s3 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s3 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c340); }
      }
      if (s3 as any !== peg$FAILED) {
        if (peg$c339.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c340); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c339.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c340); }
          }
          if (s5 as any !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 45) {
              s6 = peg$c54;
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c55); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c339.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c340); }
              }
              if (s7 as any !== peg$FAILED) {
                if (peg$c339.test(input.charAt(peg$currPos))) {
                  s8 = input.charAt(peg$currPos);
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c340); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (input.charCodeAt(peg$currPos) === 45) {
                    s9 = peg$c54;
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c55); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c339.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c340); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (peg$c339.test(input.charAt(peg$currPos))) {
                        s11 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c340); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 84) {
                          s12 = peg$c348;
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c349); }
                        }
                        if (s12 as any !== peg$FAILED) {
                          if (peg$c339.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c340); }
                          }
                          if (s13 as any !== peg$FAILED) {
                            if (peg$c339.test(input.charAt(peg$currPos))) {
                              s14 = input.charAt(peg$currPos);
                              peg$currPos++;
                            } else {
                              s14 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c340); }
                            }
                            if (s14 as any !== peg$FAILED) {
                              if (input.charCodeAt(peg$currPos) === 58) {
                                s15 = peg$c350;
                                peg$currPos++;
                              } else {
                                s15 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c351); }
                              }
                              if (s15 as any !== peg$FAILED) {
                                if (peg$c339.test(input.charAt(peg$currPos))) {
                                  s16 = input.charAt(peg$currPos);
                                  peg$currPos++;
                                } else {
                                  s16 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                }
                                if (s16 as any !== peg$FAILED) {
                                  if (peg$c339.test(input.charAt(peg$currPos))) {
                                    s17 = input.charAt(peg$currPos);
                                    peg$currPos++;
                                  } else {
                                    s17 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                  }
                                  if (s17 as any !== peg$FAILED) {
                                    if (input.charCodeAt(peg$currPos) === 58) {
                                      s18 = peg$c350;
                                      peg$currPos++;
                                    } else {
                                      s18 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c351); }
                                    }
                                    if (s18 as any !== peg$FAILED) {
                                      if (peg$c339.test(input.charAt(peg$currPos))) {
                                        s19 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                      } else {
                                        s19 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                      }
                                      if (s19 as any !== peg$FAILED) {
                                        if (peg$c339.test(input.charAt(peg$currPos))) {
                                          s20 = input.charAt(peg$currPos);
                                          peg$currPos++;
                                        } else {
                                          s20 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                        }
                                        if (s20 as any !== peg$FAILED) {
                                          s2 = [s2, s3, s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20];
                                          s1 = s2;
                                        } else {
                                          peg$currPos = s1;
                                          s1 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s1;
                                        s1 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s1;
                                      s1 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s1;
                                    s1 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s1;
                                  s1 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s1;
                                s1 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s1;
                              s1 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s1;
                            s1 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s1;
                          s1 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s1;
                        s1 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s1;
                      s1 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s1;
                    s1 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s1;
                  s1 = peg$FAILED;
                }
              } else {
                peg$currPos = s1;
                s1 = peg$FAILED;
              }
            } else {
              peg$currPos = s1;
              s1 = peg$FAILED;
            }
          } else {
            peg$currPos = s1;
            s1 = peg$FAILED;
          }
        } else {
          peg$currPos = s1;
          s1 = peg$FAILED;
        }
      } else {
        peg$currPos = s1;
        s1 = peg$FAILED;
      }
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.substr(peg$currPos, 4) === peg$c341) {
        s2 = peg$c341;
        peg$currPos += 4;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c342); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$currPos;
        if (peg$c339.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c340); }
        }
        if (s4 as any !== peg$FAILED) {
          if (peg$c339.test(input.charAt(peg$currPos))) {
            s5 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c340); }
          }
          if (s5 as any !== peg$FAILED) {
            if (peg$c339.test(input.charAt(peg$currPos))) {
              s6 = input.charAt(peg$currPos);
              peg$currPos++;
            } else {
              s6 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c340); }
            }
            if (s6 as any !== peg$FAILED) {
              if (peg$c339.test(input.charAt(peg$currPos))) {
                s7 = input.charAt(peg$currPos);
                peg$currPos++;
              } else {
                s7 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c340); }
              }
              if (s7 as any !== peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 45) {
                  s8 = peg$c54;
                  peg$currPos++;
                } else {
                  s8 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c55); }
                }
                if (s8 as any !== peg$FAILED) {
                  if (peg$c343.test(input.charAt(peg$currPos))) {
                    s9 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s9 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c344); }
                  }
                  if (s9 as any !== peg$FAILED) {
                    if (peg$c339.test(input.charAt(peg$currPos))) {
                      s10 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s10 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c340); }
                    }
                    if (s10 as any !== peg$FAILED) {
                      if (input.charCodeAt(peg$currPos) === 45) {
                        s11 = peg$c54;
                        peg$currPos++;
                      } else {
                        s11 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c55); }
                      }
                      if (s11 as any !== peg$FAILED) {
                        if (peg$c345.test(input.charAt(peg$currPos))) {
                          s12 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s12 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c346); }
                        }
                        if (s12 as any !== peg$FAILED) {
                          if (peg$c339.test(input.charAt(peg$currPos))) {
                            s13 = input.charAt(peg$currPos);
                            peg$currPos++;
                          } else {
                            s13 = peg$FAILED;
                            if (peg$silentFails === 0) { peg$fail(peg$c340); }
                          }
                          if (s13 as any !== peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 84) {
                              s14 = peg$c348;
                              peg$currPos++;
                            } else {
                              s14 = peg$FAILED;
                              if (peg$silentFails === 0) { peg$fail(peg$c349); }
                            }
                            if (s14 as any !== peg$FAILED) {
                              if (peg$c339.test(input.charAt(peg$currPos))) {
                                s15 = input.charAt(peg$currPos);
                                peg$currPos++;
                              } else {
                                s15 = peg$FAILED;
                                if (peg$silentFails === 0) { peg$fail(peg$c340); }
                              }
                              if (s15 as any !== peg$FAILED) {
                                if (peg$c339.test(input.charAt(peg$currPos))) {
                                  s16 = input.charAt(peg$currPos);
                                  peg$currPos++;
                                } else {
                                  s16 = peg$FAILED;
                                  if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                }
                                if (s16 as any !== peg$FAILED) {
                                  if (input.charCodeAt(peg$currPos) === 58) {
                                    s17 = peg$c350;
                                    peg$currPos++;
                                  } else {
                                    s17 = peg$FAILED;
                                    if (peg$silentFails === 0) { peg$fail(peg$c351); }
                                  }
                                  if (s17 as any !== peg$FAILED) {
                                    if (peg$c339.test(input.charAt(peg$currPos))) {
                                      s18 = input.charAt(peg$currPos);
                                      peg$currPos++;
                                    } else {
                                      s18 = peg$FAILED;
                                      if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                    }
                                    if (s18 as any !== peg$FAILED) {
                                      if (peg$c339.test(input.charAt(peg$currPos))) {
                                        s19 = input.charAt(peg$currPos);
                                        peg$currPos++;
                                      } else {
                                        s19 = peg$FAILED;
                                        if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                      }
                                      if (s19 as any !== peg$FAILED) {
                                        if (input.charCodeAt(peg$currPos) === 58) {
                                          s20 = peg$c350;
                                          peg$currPos++;
                                        } else {
                                          s20 = peg$FAILED;
                                          if (peg$silentFails === 0) { peg$fail(peg$c351); }
                                        }
                                        if (s20 as any !== peg$FAILED) {
                                          if (peg$c339.test(input.charAt(peg$currPos))) {
                                            s21 = input.charAt(peg$currPos);
                                            peg$currPos++;
                                          } else {
                                            s21 = peg$FAILED;
                                            if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                          }
                                          if (s21 as any !== peg$FAILED) {
                                            if (peg$c339.test(input.charAt(peg$currPos))) {
                                              s22 = input.charAt(peg$currPos);
                                              peg$currPos++;
                                            } else {
                                              s22 = peg$FAILED;
                                              if (peg$silentFails === 0) { peg$fail(peg$c340); }
                                            }
                                            if (s22 as any !== peg$FAILED) {
                                              s4 = [s4, s5, s6, s7, s8, s9, s10, s11, s12, s13, s14, s15, s16, s17, s18, s19, s20, s21, s22];
                                              s3 = s4;
                                            } else {
                                              peg$currPos = s3;
                                              s3 = peg$FAILED;
                                            }
                                          } else {
                                            peg$currPos = s3;
                                            s3 = peg$FAILED;
                                          }
                                        } else {
                                          peg$currPos = s3;
                                          s3 = peg$FAILED;
                                        }
                                      } else {
                                        peg$currPos = s3;
                                        s3 = peg$FAILED;
                                      }
                                    } else {
                                      peg$currPos = s3;
                                      s3 = peg$FAILED;
                                    }
                                  } else {
                                    peg$currPos = s3;
                                    s3 = peg$FAILED;
                                  }
                                } else {
                                  peg$currPos = s3;
                                  s3 = peg$FAILED;
                                }
                              } else {
                                peg$currPos = s3;
                                s3 = peg$FAILED;
                              }
                            } else {
                              peg$currPos = s3;
                              s3 = peg$FAILED;
                            }
                          } else {
                            peg$currPos = s3;
                            s3 = peg$FAILED;
                          }
                        } else {
                          peg$currPos = s3;
                          s3 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s3;
                        s3 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s3;
                      s3 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s3;
                    s3 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s3;
                  s3 = peg$FAILED;
                }
              } else {
                peg$currPos = s3;
                s3 = peg$FAILED;
              }
            } else {
              peg$currPos = s3;
              s3 = peg$FAILED;
            }
          } else {
            peg$currPos = s3;
            s3 = peg$FAILED;
          }
        } else {
          peg$currPos = s3;
          s3 = peg$FAILED;
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c347(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsevalue(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parsepercentage();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsereal();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseinteger();
        if (s0 as any === peg$FAILED) {
          s0 = peg$parsenull();
          if (s0 as any === peg$FAILED) {
            s0 = peg$parsestring();
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c352); }
    }

    return s0;
  }

  function peg$parsenumeric(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = peg$parsepercentage();
    if (s0 as any === peg$FAILED) {
      s0 = peg$parsereal();
      if (s0 as any === peg$FAILED) {
        s0 = peg$parseinteger();
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c353); }
    }

    return s0;
  }

  function peg$parsenull(): any {
    let s0;

    if (input.substr(peg$currPos, 4) === peg$c354) {
      s0 = peg$c354;
      peg$currPos += 4;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c355); }
    }

    return s0;
  }

  function peg$parseintegerList(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parseinteger();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsesubsequentInteger();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c356(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsesubsequentInteger(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c243;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c244); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parseinteger();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsesubsequentInteger();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c356(s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsestringList(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$parsestring();
    if (s1 as any !== peg$FAILED) {
      s2 = peg$parsesubsequentString();
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c356(s1, s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsesubsequentString(): any {
    let s0, s1, s2, s3, s4, s5;

    s0 = peg$currPos;
    s1 = peg$parses();
    if (s1 as any === peg$FAILED) {
      s1 = null;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 44) {
        s2 = peg$c243;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c244); }
      }
      if (s2 as any !== peg$FAILED) {
        s3 = peg$parses();
        if (s3 as any === peg$FAILED) {
          s3 = null;
        }
        if (s3 as any !== peg$FAILED) {
          s4 = peg$parsestring();
          if (s4 as any !== peg$FAILED) {
            s5 = peg$parsesubsequentString();
            if (s5 as any === peg$FAILED) {
              s5 = null;
            }
            if (s5 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c356(s4, s5);
              s0 = s1;
            } else {
              peg$currPos = s0;
              s0 = peg$FAILED;
            }
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parsealphas(): any {
    let s0, s1;

    s0 = [];
    if (peg$c357.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c358); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c357.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c358); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }

    return s0;
  }

  function peg$parses(): any {
    let s0, s1;

    peg$silentFails++;
    s0 = [];
    if (peg$c360.test(input.charAt(peg$currPos))) {
      s1 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c361); }
    }
    if (s1 as any !== peg$FAILED) {
      while (s1 as any !== peg$FAILED) {
        s0.push(s1);
        if (peg$c360.test(input.charAt(peg$currPos))) {
          s1 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s1 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c361); }
        }
      }
    } else {
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c359); }
    }

    return s0;
  }

  function peg$parsepercentage(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = peg$parseinteger();
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 37) {
        s2 = peg$c363;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c364); }
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c365(s1);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c362); }
    }

    return s0;
  }

  function peg$parseinteger(): any {
    let s0, s1, s2;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c367.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c368); }
    }
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        if (peg$c367.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c368); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      peg$savedPos = s0;
      s1 = peg$c369(s1);
    }
    s0 = s1;
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c366); }
    }

    return s0;
  }

  function peg$parsereal(): any {
    let s0, s1, s2, s3, s4;

    peg$silentFails++;
    s0 = peg$currPos;
    s1 = [];
    if (peg$c367.test(input.charAt(peg$currPos))) {
      s2 = input.charAt(peg$currPos);
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c368); }
    }
    if (s2 as any !== peg$FAILED) {
      while (s2 as any !== peg$FAILED) {
        s1.push(s2);
        if (peg$c367.test(input.charAt(peg$currPos))) {
          s2 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s2 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c368); }
        }
      }
    } else {
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 46) {
        s2 = peg$c371;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c372); }
      }
      if (s2 as any === peg$FAILED) {
        s2 = null;
      }
      if (s2 as any !== peg$FAILED) {
        s3 = [];
        if (peg$c339.test(input.charAt(peg$currPos))) {
          s4 = input.charAt(peg$currPos);
          peg$currPos++;
        } else {
          s4 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c340); }
        }
        while (s4 as any !== peg$FAILED) {
          s3.push(s4);
          if (peg$c339.test(input.charAt(peg$currPos))) {
            s4 = input.charAt(peg$currPos);
            peg$currPos++;
          } else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c340); }
          }
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c373(s1, s3);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c370); }
    }

    return s0;
  }

  function peg$parsestring(): any {
    let s0, s1, s2, s3;

    peg$silentFails++;
    s0 = peg$currPos;
    if (input.charCodeAt(peg$currPos) === 34) {
      s1 = peg$c375;
      peg$currPos++;
    } else {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c376); }
    }
    if (s1 as any !== peg$FAILED) {
      s2 = [];
      s3 = peg$parsedoubleQuoteStringChar();
      while (s3 as any !== peg$FAILED) {
        s2.push(s3);
        s3 = peg$parsedoubleQuoteStringChar();
      }
      if (s2 as any !== peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 34) {
          s3 = peg$c375;
          peg$currPos++;
        } else {
          s3 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c376); }
        }
        if (s3 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c377(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 39) {
        s1 = peg$c288;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c289); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = [];
        s3 = peg$parsesingleQuoteStringChar();
        while (s3 as any !== peg$FAILED) {
          s2.push(s3);
          s3 = peg$parsesingleQuoteStringChar();
        }
        if (s2 as any !== peg$FAILED) {
          if (input.charCodeAt(peg$currPos) === 39) {
            s3 = peg$c288;
            peg$currPos++;
          } else {
            s3 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c289); }
          }
          if (s3 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c377(s2);
            s0 = s1;
          } else {
            peg$currPos = s0;
            s0 = peg$FAILED;
          }
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c374); }
    }

    return s0;
  }

  function peg$parsedoubleQuoteStringChar(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 34) {
      s2 = peg$c375;
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c376); }
    }
    if (s2 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 92) {
        s2 = peg$c378;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c379); }
      }
    }
    peg$silentFails--;
    if (s2 as any === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c380); }
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c381(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 92) {
        s1 = peg$c378;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c379); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parseescapeSequence();
        if (s2 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c382(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parsesingleQuoteStringChar(): any {
    let s0, s1, s2;

    s0 = peg$currPos;
    s1 = peg$currPos;
    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 39) {
      s2 = peg$c288;
      peg$currPos++;
    } else {
      s2 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c289); }
    }
    if (s2 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 92) {
        s2 = peg$c378;
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c379); }
      }
    }
    peg$silentFails--;
    if (s2 as any === peg$FAILED) {
      s1 = undefined;
    } else {
      peg$currPos = s1;
      s1 = peg$FAILED;
    }
    if (s1 as any !== peg$FAILED) {
      if (input.length > peg$currPos) {
        s2 = input.charAt(peg$currPos);
        peg$currPos++;
      } else {
        s2 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c380); }
      }
      if (s2 as any !== peg$FAILED) {
        peg$savedPos = s0;
        s1 = peg$c381(s2);
        s0 = s1;
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    } else {
      peg$currPos = s0;
      s0 = peg$FAILED;
    }
    if (s0 as any === peg$FAILED) {
      s0 = peg$currPos;
      if (input.charCodeAt(peg$currPos) === 92) {
        s1 = peg$c378;
        peg$currPos++;
      } else {
        s1 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c379); }
      }
      if (s1 as any !== peg$FAILED) {
        s2 = peg$parseescapeSequence();
        if (s2 as any !== peg$FAILED) {
          peg$savedPos = s0;
          s1 = peg$c382(s2);
          s0 = s1;
        } else {
          peg$currPos = s0;
          s0 = peg$FAILED;
        }
      } else {
        peg$currPos = s0;
        s0 = peg$FAILED;
      }
    }

    return s0;
  }

  function peg$parseescapeSequence(): any {
    let s0, s1, s2, s3, s4, s5, s6;

    peg$silentFails++;
    if (input.charCodeAt(peg$currPos) === 39) {
      s0 = peg$c288;
      peg$currPos++;
    } else {
      s0 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c289); }
    }
    if (s0 as any === peg$FAILED) {
      if (input.charCodeAt(peg$currPos) === 34) {
        s0 = peg$c375;
        peg$currPos++;
      } else {
        s0 = peg$FAILED;
        if (peg$silentFails === 0) { peg$fail(peg$c376); }
      }
      if (s0 as any === peg$FAILED) {
        if (input.charCodeAt(peg$currPos) === 92) {
          s0 = peg$c378;
          peg$currPos++;
        } else {
          s0 = peg$FAILED;
          if (peg$silentFails === 0) { peg$fail(peg$c379); }
        }
        if (s0 as any === peg$FAILED) {
          s0 = peg$currPos;
          if (input.charCodeAt(peg$currPos) === 116) {
            s1 = peg$c384;
            peg$currPos++;
          } else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) { peg$fail(peg$c385); }
          }
          if (s1 as any !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$c386();
          }
          s0 = s1;
          if (s0 as any === peg$FAILED) {
            s0 = peg$currPos;
            if (input.charCodeAt(peg$currPos) === 110) {
              s1 = peg$c387;
              peg$currPos++;
            } else {
              s1 = peg$FAILED;
              if (peg$silentFails === 0) { peg$fail(peg$c388); }
            }
            if (s1 as any !== peg$FAILED) {
              peg$savedPos = s0;
              s1 = peg$c389();
            }
            s0 = s1;
            if (s0 as any === peg$FAILED) {
              s0 = peg$currPos;
              if (input.charCodeAt(peg$currPos) === 114) {
                s1 = peg$c390;
                peg$currPos++;
              } else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) { peg$fail(peg$c391); }
              }
              if (s1 as any !== peg$FAILED) {
                peg$savedPos = s0;
                s1 = peg$c392();
              }
              s0 = s1;
              if (s0 as any === peg$FAILED) {
                s0 = peg$currPos;
                if (input.charCodeAt(peg$currPos) === 117) {
                  s1 = peg$c393;
                  peg$currPos++;
                } else {
                  s1 = peg$FAILED;
                  if (peg$silentFails === 0) { peg$fail(peg$c394); }
                }
                if (s1 as any !== peg$FAILED) {
                  s2 = peg$currPos;
                  if (peg$c395.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                  } else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) { peg$fail(peg$c396); }
                  }
                  if (s3 as any !== peg$FAILED) {
                    if (peg$c395.test(input.charAt(peg$currPos))) {
                      s4 = input.charAt(peg$currPos);
                      peg$currPos++;
                    } else {
                      s4 = peg$FAILED;
                      if (peg$silentFails === 0) { peg$fail(peg$c396); }
                    }
                    if (s4 as any !== peg$FAILED) {
                      if (peg$c395.test(input.charAt(peg$currPos))) {
                        s5 = input.charAt(peg$currPos);
                        peg$currPos++;
                      } else {
                        s5 = peg$FAILED;
                        if (peg$silentFails === 0) { peg$fail(peg$c396); }
                      }
                      if (s5 as any !== peg$FAILED) {
                        if (peg$c395.test(input.charAt(peg$currPos))) {
                          s6 = input.charAt(peg$currPos);
                          peg$currPos++;
                        } else {
                          s6 = peg$FAILED;
                          if (peg$silentFails === 0) { peg$fail(peg$c396); }
                        }
                        if (s6 as any !== peg$FAILED) {
                          s3 = [s3, s4, s5, s6];
                          s2 = s3;
                        } else {
                          peg$currPos = s2;
                          s2 = peg$FAILED;
                        }
                      } else {
                        peg$currPos = s2;
                        s2 = peg$FAILED;
                      }
                    } else {
                      peg$currPos = s2;
                      s2 = peg$FAILED;
                    }
                  } else {
                    peg$currPos = s2;
                    s2 = peg$FAILED;
                  }
                  if (s2 as any !== peg$FAILED) {
                    peg$savedPos = s0;
                    s1 = peg$c397(s2);
                    s0 = s1;
                  } else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                  }
                } else {
                  peg$currPos = s0;
                  s0 = peg$FAILED;
                }
              }
            }
          }
        }
      }
    }
    peg$silentFails--;
    if (s0 as any === peg$FAILED) {
      s1 = peg$FAILED;
      if (peg$silentFails === 0) { peg$fail(peg$c383); }
    }

    return s0;
  }

  peg$result = peg$startRuleFunction();

  if (peg$result !== peg$FAILED && peg$currPos === input.length) {
    return peg$result;
  } else {
    if (peg$result !== peg$FAILED && peg$currPos < input.length) {
      peg$fail(peg$endExpectation());
    }

    throw peg$buildStructuredError(
      peg$maxFailExpected,
      peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null,
      peg$maxFailPos < input.length
        ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
        : peg$computeLocation(peg$maxFailPos, peg$maxFailPos)
    );
  }
}

export interface ParseOptions {
  filename?: string;
  startRule?: string;
  tracer?: any;
  [key: string]: any;
}
export type ParseFunction = (input: string, options?: ParseOptions) => any;
export const parse: ParseFunction = peg$parse;

