import { format } from 'd3-format';
import { timeFormat, timeParse } from 'd3-time-format';
import VueI18n from 'vue-i18n';

let i18n: VueI18n | null = null;

export function setI18n(val: VueI18n) {
    i18n = val;
    date = timeFormat(i18n.t('timestamps.shortDate'));
    datetime = timeFormat(i18n.t('timestamps.shortDateTime'));
}

export function setCurrency(symbol: string) {
    if (symbol == '€') {
        // Postfix currency symbols
        value = (val: number) => format('.3s')(val) + symbol;
        smallValue = (val: number) => format('.2f')(val) + symbol;
    } else {
        // Default to prefix
        value = (val: number) => symbol + format('.3s')(val);
        smallValue = (val: number) => symbol + format('.2f')(val);
    }
}

export const g = format(',d');
export const si = format('.2s');
export const pct = format('.1%');
export let date = timeFormat('%e %b %Y');
export let datetime = timeFormat('%H:%M, %e %b %Y');
export const iso8601date = timeFormat('%Y-%m-%d');
export const iso8601dateParse = timeParse('%Y-%m-%d');
export const iso8601datetime = timeFormat('%Y-%m-%dT%H:%M:%SZ');
export const smallPct = format('.2%');
export const largePct = format('.0%');
export const scalar = format('.3s');
export let value = format('$.3s');
export let smallValue = format('$.2f');

export function metric(m: string, v: number | undefined, pctBase?: number) {
    if (v === undefined || v === null || isNaN(v)) {
        return '-';
    } else if (m.indexOf('value') !== -1) {
        return v < 1.0 ? smallValue(v) : value(v);
    } else if (typeof pctBase === 'number' && pctBase > 0) {
        const pdmil = Math.round(((v || 0) / pctBase) * 10000.0);
        return (pdmil < 100.0 ? smallPct : pdmil < 10000.0 ? pct : largePct)(pdmil * 0.0001);
    } else if (pctBase !== null && pctBase !== undefined) {
        return '-';
    } else {
        return !v || v < 1000 ? Math.round(v) : scalar(v);
    }
}

export function fileSize(bytes: number) {
    if (bytes === 0) {
        return '0 B';
    }

    if (bytes < 0) {
        throw new Error(`Invalid file size (${bytes})`);
    }

    const prefixes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];

    const prefix = parseInt(Math.log(bytes) / (Math.LN2 * 10) + '', 10);
    return `${(bytes / Math.pow(2, prefix * 10)).toFixed(0)} ${prefixes[prefix]}`;
}

export function capitalize(text: string) {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export function emailToName(email: string) {
    if (!email || email.indexOf('@') === -1) {
        return email;
    }
    const address = email.split('@')[0];
    let name = capitalize(address);
    if (address.indexOf('.') > 2) {
        name = [].map
            .call(address.split('.'), (n: string) => {
                return capitalize(n);
            })
            .join(' ');
    }
    return name;
}
